.banking {
    /* background-image: url("../../assests/images/background/Infraguard-Web-Background.jpg"); */
    background-color: #ef687d;
    height: 58vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .overly {
    background-color: #ef687d;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
  .contactus_parallax3 {
    background-image: url("../../assests/images/photo/Banking-and-Financial-ServicesV2d.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
  }
  .bank{
    text-align: justify;
    font-size: 17px;
  font-weight: 500;
  color: black;
  margin-top: 0;
  margin-bottom: 0.9rem;
  font-family: montserrat, sans-serif;
}

  .toge{
    font-size: 31px;
    font-weight: 900;
    color: black;
  }
   /* ipadpro */
   @media screen and (min-width: 991px) and (max-width: 1030px) {
    .banking {
      height: 30vh;
    }
  }
    /* landscape */
@media screen and (min-width: 1366px) and (min-height: 1024px) {
  .banking {
    height: 50vh;
  }
}

/* mobile */
@media screen and (max-width: 768px) {
  .banking {
    height: 50vh;
  }
  .maati{
font-size: 25px;
  }
  .finch{
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .toge {
    font-size: 16px;
    margin-left: -35px;
  }
  .bank{
    width: 125%;
    margin-left: -35px;
  }
  .featt{
    margin-top: -95px !important;
  }
}