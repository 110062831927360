.video{
  background-image: url("../../assests/images/photo/Infraguard-Web-Background.webp");
    height: 58vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .overlay {
    background-color: #22222242;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
  .secure{
font-size: 19px;
font-family: montserrat, sans-serif !important;
font-weight: 700;
    color: #ef687d;
    /* font-family: montserrat, sans-serif; */
  }   
  .overview{
    font-size: 25px;
    color: black;
    font-weight: 800;
    font-family: montserrat, sans-serif !important;
  } 
  .onboarding{
    font-size: 20px;
    color: black;
    font-weight: 800;
    font-family: montserrat, sans-serif !important;
  }

  /* ipadpro */
@media screen and (min-width: 991px) and (max-width: 1030px) {
  .video{
    height: 30vh;
  }
}

 /* landscape */
 @media screen and (min-width: 1366px) and (min-height: 1024px) {
  .video{
    height: 50vh;
  }
}
    /* mobile */
    @media screen and (max-width: 768px) {
      .video{
        height: 40vh;
      }   
      .secure{
        font-size: 15px;
        margin-left: 12px;
      
      }
      .introduction{
        margin-left: 12px;
      }
      .onboarding{
        /* margin-left: 10px; */
      }
      .utube{
width: 85%;
      }
    }