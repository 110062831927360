.resu{
    /* margin-left: 245px; */
   height: 48%;
   width: 100%;
}
.res{
    /* margin-left: 245px; */
   height: 90%;
   width: 100%;
}
.second{
 box-sizing :border-box;
color :rgb(0, 0, 0);
/* display :block; */
font-family: Montserrat, sans-serif;
font-size :16px;
font-weight :500;
line-height:  25px;
/* margin-block-end: 14.4px; */
/* margin-block-start: 0px; */
/* margin-bottom: 14.4px; */
/* margin-inline-end: 0px; */
/* margin-inline-start :0px; */
/* margin-top :0px; */
text-align :justify;
width :100%;
/* -webkit-font-smoothing :antialiased; */
}
.secondd{
    box-sizing :border-box;
    color :rgb(0, 0, 0);
    /* display :block; */
    font-family: Montserrat, sans-serif;
    font-size :16px;
    font-weight :500;
    line-height:  25px;
}

    /* mobile */
    @media screen and (max-width: 768px) {
        .acxs{
            font-size: 18px;
        }
        .smyt{
            margin-top: 22px;
            margin-left: 1px;
        }
        .featts{
            margin-top: -119px;
        }
        .featst{
            margin-top: -150px;
        }
        /* .bene{
            display: none;
        } */
      
        .second{
            
            margin-left: 12px !important;
            font-size: 18px !important;
            width: 93% !important;
        }
        .secondd{
           
            margin-left: 2px !important;
            font-size: 18px !important;
            width: 100% !important;
        }
        .blaze{
            margin-left: 12px ;
        }
        .bene{
            /* margin-top: 405px; */
            display: none;
        }
      
    }