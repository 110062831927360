@import url('https://fonts.googleapis.com/css2?  family=Montserrat:ital,wght@0,100;0,200;1,100;1,200&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  line-height: 26px !important;
}

code {
  font-family: montserrat, sans-serif ;
}
::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: montserrat, sans-serif !important;
  src: url(../src/Montserrat_Alternates/MontserratAlternates-BoldItalic.ttf);
}