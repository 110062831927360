
/* mobile */
@media screen and (max-width: 768px) {
    .manged{
        font-size: 35px 
        !important;
        width: 80%;
        margin-top: -1px 
        !important;
        /* margin-left: 12px; */
    }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .manged{
        font-size: 20px 
        !important;
        margin-top: 1px 
        !important;
        margin-left: 11px;
    }
}